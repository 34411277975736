<template>
  <div class="min-h-screen">
    <div v-if="stillLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>
    <div v-else>
      <div class="flex justify-between items-center">
        <h1 class="text-3xl font-bold">Certificate</h1>
        <Button buttonText="Create New Template" @action="toogleVisibleModal(false)" />
      </div>
      <div class="flex items-center mt-5 mb-5">
        <div class="w-1/4">
          <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Search Certificate</label>
          <div class="relative">
            <TextField type="text" v-model="query" :enterKeyAction="doSearch" withIcon borderEnabled placeholder="Search certficate by name..." />
            <div class="absolute left-3 top-1/2 transform -translate-y-1/2">
              <Search color="#58595B" />
            </div>
          </div>
        </div>
        <div class="flex items-center h-full ml-3 mt-5">
          <Button buttonText="Search" @action="doSearch()" additionalClass="h-11" />
        </div>
      </div>
      <div class="table w-full border border-neutral-250 rounded-lg">
        <div class="font-bold header flex justify-between bg-grey-header py-3 px-3 rounded-t-lg">
          <div class="w-1/4">Template Name</div>
          <div class="w-1/2">Values</div>
          <div class="w-1/4">Action</div>
        </div>
        <template v-if="certificateList.data.length > 0">
          <div class="body flex justify-between items-center py-3 px-3 my-2" v-for="certificate in certificateList.data" :key="certificate.id">
            <div class="w-1/4">{{ certificate.template_name }}</div>
            <div class="w-1/2 flex">
              <div v-for="(items, index) in certificate.items" :key="index">{{ convertName(items) }} <span class="mr-2" :class="index + 1 === certificate.items.length ? 'hidden' : ''">,</span></div>
            </div>
            <div class="w-1/4 grid grid-cols-3 gap-2">
              <div class="w-full">
                <Button buttonText="Edit" size="full" @action="editCertificate(certificate)" />
              </div>
              <div class="w-full">
                <Button buttonText="Delete" type="delete" size="full" @action="toogleDeleteModal(certificate.id)" />
              </div>
              <div class="w-full">
                <Button buttonText="Preview" size="full" type="tertiary" @action="tooglePreviewModal(certificate.id)" />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="py-20 text-center">No templates available</div>
        </template>
      </div>
      <div class="flex justify-between items-center mt-8" v-if="totalRecords > 10">
        <div class="text-neutral-500 font-medium mt-2">Showing {{ start }} - {{ end }} out of {{ totalRecords }}</div>
        <div>
          <pagination :currentPage="currentPage" :totalCount="totalRecords" :pageSize="pageSize" @onPageChange="onPageChange" />
        </div>
      </div>
    </div>
    <Modal :modalVisible="isModalVisible" closeIconPosition="top-left" @close="toogleVisibleModal(true)" width="800px" id="delete">
      <template slot="modal-content">
        <div class="py-8 text-left">
          <ChooseFile v-if="stepActive === 1" @selectFile="selectFile" :templateUrl="templateUrl" />
          <SelectValue
            v-if="stepActive === 2"
            :step2Title="step2Title"
            :valueListEdit="valueListEdit"
            @save="saveValueList"
            :valueList="valueList"
            :templateName="templateName"
            :reloadData="getData"
            @input="handleInput"
            :isEditMode="isEditMode"
          />
        </div>
      </template>
    </Modal>
    <Modal :modalVisible="isDeleteModalVisible" :closeIconVisible="false" width="300px" @close="toogleDeleteModal" id="delete">
      <template slot="modal-content">
        <div class="py-2">
          <p class="text-xl font-bold mb-4">Are you sure?</p>
          <div class="flex justify-center items-center h-full">
            <Button buttonText="Delete" type="delete" size="small" @action="deleteCertificate(selectedTempleteId)" additionalClass="h-11 mr-5" />
            <Button buttonText="Cancel" type="tertiary" size="small" @action="toogleDeleteModal" additionalClass="h-11" />
          </div>
        </div>
      </template>
    </Modal>
    <Modal :modalVisible="isPreviewModalVisible" :closeIconVisible="false" width="750px" @close="tooglePreviewModal" id="delete">
      <template slot="modal-content">
        <div v-if="!isImageReady" class="loading-page">
          <vue-simple-spinner size="large" message="Loading Image ..."></vue-simple-spinner>
        </div>
        <div>
          <img id="previewImage" />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { showVueToast } from '@/utils'
export default {
  name: 'Certificate',
  components: {
    ChooseFile: () => import(/* webpackChunkName: "Button" */ './ChooseFile.vue'),
    SelectValue: () => import(/* webpackChunkName: "Button" */ './SelectValue.vue'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Pagination: () => import(/* webpackChunkName: "Pagination" */ '@/components/Pagination/Pagination'),
    Close: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Close')
  },
  mounted() {
    this.getData()
  },
  data() {
    return {
      fileList: [
        {
          id: 1,
          name: 'Template 1 Template 1 Template 1',
          date: '12 Nov 2021 14:00',
          image: ''
        },
        {
          id: 2,
          name: 'Template 2',
          date: '12 Nov 2021 14:00',
          image: ''
        },
        {
          id: 3,
          name: 'Template 3',
          date: '12 Nov 2021 14:00',
          image: ''
        }
      ],
      valueList: [null],
      query: '',
      optionList: [
        {
          name: 'Student Name',
          id: 'name'
        },
        {
          name: 'Post Test Score',
          id: 'score'
        },
        {
          name: 'Post Test Date',
          id: 'certificateDate'
        },
        {
          name: 'Course Title',
          id: 'courseTitle'
        },
        {
          name: 'Competence List',
          id: 'competenceList'
        },
        {
          name: 'Certificate Number',
          id: 'certificateNumber'
        },
        {
          name: 'Publish Date',
          id: 'publishDate'
        },
        {
          name: 'QR Code',
          id: 'qrcode'
        }
      ],
      isModalVisible: false,
      isDeleteModalVisible: false,
      isPreviewModalVisible: false,
      selectedTempleteId: '',
      keyword: '',
      step2Title: 'Step 2 : Select Value',
      templateName: '',
      templateUrl: '',
      isEditMode: false,
      itemCodes: [],
      previewTemplate: '',
      totalRecords: 0,
      stepActive: 1,
      currentPage: 1,
      pageSize: 10,
      certificateId: '',
      valueListEdit: [],
      /////////////////////////////////
      stillLoading: true,
      isImageReady: false
    }
  },
  methods: {
    ...mapActions('common', ['DIGITAL_PLATFORMS', 'showLoading', 'hideLoading']),
    ...mapActions('certificate', ['PREVIEW_CERTIFICATE', 'UPDATE_CERTIFICATE']),
    handleInput(value, state) {
      if (state === 'templateName') this.templateName = value
      if (state === 'valueList') this.valueList = value
    },
    doSearch() {
      this.currentPage = 1
      this.getData(this.query)
    },
    getData(searchTerm = '') {
      this.showLoading()
      this.stillLoading = true
      this.$store
        .dispatch('certificate/GET_CERTIFICATE_LIST', {
          params: {
            customerId: this.clientId,
            size: this.pageSize,
            page: this.currentPage,
            templateName: searchTerm
          }
        })
        .then((res) => {
          this.stillLoading = false
          this.hideLoading()
          this.totalRecords = res.pagination.total
        })
        .catch(() => this.hideLoading())
    },
    convertName(itemName) {
      switch (itemName) {
        case 'name':
          return 'Student Name'
        case 'score':
          return 'Post Test Score'
        case 'certificateDate':
          return 'Post Test Date'
        case 'publishDate':
          return 'Publish Date'
        case 'courseTitle':
          return 'Course Title'
        case 'competenceList':
          return 'Competence List'
        case 'certificateNumber':
          return 'Certificate Number'
        case 'qrcode':
          return 'QR Code'
        default:
          return 'Student Name'
      }
    },
    deleteCertificate(id) {
      this.toogleDeleteModal()
      this.showLoading()
      this.$store
        .dispatch('certificate/DELETE_CERTIFICATE', {
          id: id
        })
        .then((res) => {
          this.getData()
          this.hideLoading()
        })
    },
    editCertificate(certificate) {
      this.isEditMode = true
      this.templateName = certificate.template_name
      this.templateUrl = certificate.template_url
      this.valueList = this.revalueData(certificate.items)
      this.certificateId = certificate.id
      this.$store
        .dispatch('certificate/GET_CERTIFICATE_DETAILS', {
          id: certificate.id,
          isCallback: true
        })
        .then((res) => {
          this.valueListEdit = []
          this.valueListEdit = res.data.items.sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
        })
      this.toogleVisibleModal(false)
    },

    revalueData(itemValue) {
      return this.optionList.filter((option) => itemValue.includes(option.id))
    },

    toogleDeleteModal(id) {
      if (id) {
        this.selectedTempleteId = id
      }
      this.isDeleteModalVisible = !this.isDeleteModalVisible
    },
    tooglePreviewModal(id) {
      this.isPreviewModalVisible = !this.isPreviewModalVisible
      if (this.isPreviewModalVisible) {
        this.isImageReady = false
        this.PREVIEW_CERTIFICATE({
          params: {
            id
          }
        }).then((res) => {
          let img = URL.createObjectURL(res)
          document.getElementById('previewImage').setAttribute('src', img)
          this.isImageReady = true
        })
      }
    },
    toogleVisibleModal(isClose) {
      if (isClose) {
        this.stepActive = 1
        this.templateName = ''
        this.templateUrl = ''
        // this.certificateId = ''
        this.valueList = [null]
        this.isEditMode = false
      }
      this.isModalVisible = !this.isModalVisible
    },
    selectFile(templateUrl) {
      this.templateUrl = templateUrl
      this.stepActive = 2
    },
    saveValueList(template_name, item_codes, item_codes_edit) {
      this.showLoading()

      if (this.isEditMode) {
        const payload = {
          customer_id: this.clientId,
          items: item_codes_edit,
          template_name,
          template_url: this.templateUrl
        }
        this.$store
          .dispatch('certificate/UPDATE_CERTIFICATE', {
            certificateId: this.certificateId,
            payload
          })
          .then(() => {
            setTimeout(() => {
              this.$router.push(`/certificate/edit/${this.certificateId}`)
            }, 300)
          }).catch((err) => {
            const message = err.errors?.error || ''
            if (message) showVueToast(message, 'error', 5000)
          }).finally(() => {
            this.hideLoading()
            this.toogleVisibleModal(true)
          })
        return
      }
      const payload = {
        customer_id: this.clientId,
        item_codes,
        template_name,
        template_url: this.templateUrl
      }
      this.$store
        .dispatch('certificate/CREATE_NEW_TEMPLATE', {
          payload
        })
        .then((res) => {
          setTimeout(() => {
            this.$router.push(`/certificate/edit/${res.data.id}`)
          }, 500)
        }).catch((err) => {
          const message = err.errors?.error || ''
          if(message) showVueToast(message, 'error', 5000)
        }).finally(() => {
          this.hideLoading()
          this.templateUrl = ''
          this.toogleVisibleModal(true)
        })
    },
    onPageChange(page) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.currentPage = page
      this.getData(this.query)
    }
  },
  computed: {
    start() {
      return (this.currentPage - 1) * this.pageSize + 1
    },
    end() {
      const lastOnPage = (this.currentPage - 1) * this.pageSize + this.pageSize
      return lastOnPage > this.totalRecords ? this.totalRecords : lastOnPage
    },
    clientId() {
      return localStorage.getItem('client')
    },
    certificateList() {
      return this.$store.getters['certificate/certificateList'] ? this.$store.getters['certificate/certificateList'] : {}
    },
    isDeleteSuccess() {
      return this.$store.getters['certificate/isDeleteSuccess'] ? this.$store.getters['certificate/isDeleteSuccess'] : false
    },
    clientId() {
      return localStorage.getItem('client')
    }
  },
  watch: {}
}
</script>
<style scoped lang="scss"></style>
