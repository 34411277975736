var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-h-screen"
  }, [_vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _c('div', [_c('div', {
    staticClass: "flex justify-between items-center"
  }, [_c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v("Certificate")]), _c('Button', {
    attrs: {
      "buttonText": "Create New Template"
    },
    on: {
      "action": function action($event) {
        return _vm.toogleVisibleModal(false);
      }
    }
  })], 1), _c('div', {
    staticClass: "flex items-center mt-5 mb-5"
  }, [_c('div', {
    staticClass: "w-1/4"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Search Certificate")]), _c('div', {
    staticClass: "relative"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "enterKeyAction": _vm.doSearch,
      "withIcon": "",
      "borderEnabled": "",
      "placeholder": "Search certficate by name..."
    },
    model: {
      value: _vm.query,
      callback: function callback($$v) {
        _vm.query = $$v;
      },
      expression: "query"
    }
  }), _c('div', {
    staticClass: "absolute left-3 top-1/2 transform -translate-y-1/2"
  }, [_c('Search', {
    attrs: {
      "color": "#58595B"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "flex items-center h-full ml-3 mt-5"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Search",
      "additionalClass": "h-11"
    },
    on: {
      "action": function action($event) {
        return _vm.doSearch();
      }
    }
  })], 1)]), _c('div', {
    staticClass: "table w-full border border-neutral-250 rounded-lg"
  }, [_vm._m(0), _vm.certificateList.data.length > 0 ? _vm._l(_vm.certificateList.data, function (certificate) {
    return _c('div', {
      key: certificate.id,
      staticClass: "body flex justify-between items-center py-3 px-3 my-2"
    }, [_c('div', {
      staticClass: "w-1/4"
    }, [_vm._v(_vm._s(certificate.template_name))]), _c('div', {
      staticClass: "w-1/2 flex"
    }, _vm._l(certificate.items, function (items, index) {
      return _c('div', {
        key: index
      }, [_vm._v(_vm._s(_vm.convertName(items)) + " "), _c('span', {
        staticClass: "mr-2",
        class: index + 1 === certificate.items.length ? 'hidden' : ''
      }, [_vm._v(",")])]);
    }), 0), _c('div', {
      staticClass: "w-1/4 grid grid-cols-3 gap-2"
    }, [_c('div', {
      staticClass: "w-full"
    }, [_c('Button', {
      attrs: {
        "buttonText": "Edit",
        "size": "full"
      },
      on: {
        "action": function action($event) {
          return _vm.editCertificate(certificate);
        }
      }
    })], 1), _c('div', {
      staticClass: "w-full"
    }, [_c('Button', {
      attrs: {
        "buttonText": "Delete",
        "type": "delete",
        "size": "full"
      },
      on: {
        "action": function action($event) {
          return _vm.toogleDeleteModal(certificate.id);
        }
      }
    })], 1), _c('div', {
      staticClass: "w-full"
    }, [_c('Button', {
      attrs: {
        "buttonText": "Preview",
        "size": "full",
        "type": "tertiary"
      },
      on: {
        "action": function action($event) {
          return _vm.tooglePreviewModal(certificate.id);
        }
      }
    })], 1)])]);
  }) : [_c('div', {
    staticClass: "py-20 text-center"
  }, [_vm._v("No templates available")])]], 2), _vm.totalRecords > 10 ? _c('div', {
    staticClass: "flex justify-between items-center mt-8"
  }, [_c('div', {
    staticClass: "text-neutral-500 font-medium mt-2"
  }, [_vm._v("Showing " + _vm._s(_vm.start) + " - " + _vm._s(_vm.end) + " out of " + _vm._s(_vm.totalRecords))]), _c('div', [_c('pagination', {
    attrs: {
      "currentPage": _vm.currentPage,
      "totalCount": _vm.totalRecords,
      "pageSize": _vm.pageSize
    },
    on: {
      "onPageChange": _vm.onPageChange
    }
  })], 1)]) : _vm._e()]), _c('Modal', {
    attrs: {
      "modalVisible": _vm.isModalVisible,
      "closeIconPosition": "top-left",
      "width": "800px",
      "id": "delete"
    },
    on: {
      "close": function close($event) {
        return _vm.toogleVisibleModal(true);
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "py-8 text-left"
  }, [_vm.stepActive === 1 ? _c('ChooseFile', {
    attrs: {
      "templateUrl": _vm.templateUrl
    },
    on: {
      "selectFile": _vm.selectFile
    }
  }) : _vm._e(), _vm.stepActive === 2 ? _c('SelectValue', {
    attrs: {
      "step2Title": _vm.step2Title,
      "valueListEdit": _vm.valueListEdit,
      "valueList": _vm.valueList,
      "templateName": _vm.templateName,
      "reloadData": _vm.getData,
      "isEditMode": _vm.isEditMode
    },
    on: {
      "save": _vm.saveValueList,
      "input": _vm.handleInput
    }
  }) : _vm._e()], 1)])], 2), _c('Modal', {
    attrs: {
      "modalVisible": _vm.isDeleteModalVisible,
      "closeIconVisible": false,
      "width": "300px",
      "id": "delete"
    },
    on: {
      "close": _vm.toogleDeleteModal
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "py-2"
  }, [_c('p', {
    staticClass: "text-xl font-bold mb-4"
  }, [_vm._v("Are you sure?")]), _c('div', {
    staticClass: "flex justify-center items-center h-full"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Delete",
      "type": "delete",
      "size": "small",
      "additionalClass": "h-11 mr-5"
    },
    on: {
      "action": function action($event) {
        return _vm.deleteCertificate(_vm.selectedTempleteId);
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "tertiary",
      "size": "small",
      "additionalClass": "h-11"
    },
    on: {
      "action": _vm.toogleDeleteModal
    }
  })], 1)])])], 2), _c('Modal', {
    attrs: {
      "modalVisible": _vm.isPreviewModalVisible,
      "closeIconVisible": false,
      "width": "750px",
      "id": "delete"
    },
    on: {
      "close": _vm.tooglePreviewModal
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [!_vm.isImageReady ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Loading Image ..."
    }
  })], 1) : _vm._e(), _c('div', [_c('img', {
    attrs: {
      "id": "previewImage"
    }
  })])])], 2)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "font-bold header flex justify-between bg-grey-header py-3 px-3 rounded-t-lg"
  }, [_c('div', {
    staticClass: "w-1/4"
  }, [_vm._v("Template Name")]), _c('div', {
    staticClass: "w-1/2"
  }, [_vm._v("Values")]), _c('div', {
    staticClass: "w-1/4"
  }, [_vm._v("Action")])]);
}]

export { render, staticRenderFns }